// src/pages/ChecarMarcar/index.js
import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import Modal from '../../components/Modal';
import { FiClock } from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify'; // Importar toast
import logoImg from '../../assets/logo.png';
import styles from './styles.module.css';
import api from '../../services/api';

const ChecarMarcar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Extrair o parâmetro idEmpresa da URL
  const queryParams = new URLSearchParams(location.search);
  const idEmpresaParam = queryParams.get('idEmpresa');

  const [empresaId, setEmpresaId] = useState(idEmpresaParam || '');
  const [empresas, setEmpresas] = useState([]);
  const [telefone, setTelefone] = useState('');
  const [agendamentos, setAgendamentos] = useState([]);
  const [proximoAgendamento, setProximoAgendamento] = useState(null);
  const [mostrarAgendamentos, setMostrarAgendamentos] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [providers, setProviders] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState('');
  const [selectedService, setSelectedService] = useState('');

  // Função para buscar empresas
  const fetchEmpresas = async () => {
    try {
      const response = await api.get('/cliente/empresas');
      setEmpresas(response.data);
    } catch (error) {
      console.error('Erro ao buscar empresas:', error);
      toast.error('Erro ao carregar empresas. Tente novamente.');
    }
  };

  // Função para buscar barbeiros e serviços com base no empresaId
  const fetchProvidersAndServices = async (idEmpresa) => {
    try {
      const providersResponse = await api.get(`/cliente/funcionario/${idEmpresa}`);
      setProviders(providersResponse.data);

      const servicesResponse = await api.get(`/cliente/servico/${idEmpresa}`);
      setServices(servicesResponse.data);
    } catch (error) {
      console.error('Erro ao buscar barbeiros e serviços:', error);
      toast.error('Erro ao carregar barbeiros e serviços. Tente novamente.');
    }
  };

  // UseEffect para verificar se idEmpresa está presente ou buscar empresas
  useEffect(() => {
    if (empresaId) {
      // Se empresaId está presente, buscar barbeiros e serviços
      fetchProvidersAndServices(empresaId);
    } else {
      // Se não há empresaId, buscar a lista de empresas
      fetchEmpresas();
    }
  }, [empresaId]);

  // Função para lidar com a seleção da empresa
  const handleSelectEmpresa = (e) => {
    const selectedId = e.target.value;
    setEmpresaId(selectedId);

    // Atualizar a URL com o parâmetro idEmpresa
    navigate(`?idEmpresa=${selectedId}`);

    toast.success('Empresa selecionada com sucesso!');
  };

  const handleCheckAppointments = async () => {
    if (!empresaId) {
      toast.warn('Por favor, selecione uma empresa antes de agendar.');
      return;
    }

    const telefoneNumerico = telefone.replace(/\D/g, '');

    if (telefoneNumerico.length !== 11) {
      toast.warn('Por favor, insira um número válido.');
      return;
    }

    try {
      const response = await api.get(`/cliente/historico/${telefoneNumerico}?idEmpresa=${empresaId}`);
      console.log('response', response);
      const agendamentos = response.data;
      console.log('agendamentos', agendamentos);

      // Verifica se a resposta indica que o cliente não foi encontrado
      if (typeof agendamentos === 'string' && agendamentos.toLowerCase().includes('cliente não encontrado')) {
        console.log('Cliente não encontrado');
        setProximoAgendamento(null);
        setAgendamentos([]);
        setMostrarAgendamentos(true);
        toast.info('Cliente não encontrado. Você pode agendar um novo horário.');
        return;
      }

      const now = new Date();

      // Separar agendamentos futuros e passados
      const futuros = agendamentos.filter((agendamento) => new Date(agendamento.data) > now);
      const anteriores = agendamentos.filter((agendamento) => new Date(agendamento.data) <= now);

      // Atualizar estados
      setProximoAgendamento(futuros.length > 0 ? futuros[0] : null); // Selecionar o mais próximo
      setAgendamentos(anteriores); // Restante fica como anteriores
      setMostrarAgendamentos(true);
      toast.success('Histórico de agendamentos carregado.');
    } catch (error) {
      console.error('Erro ao buscar histórico de agendamentos:', error);

      // Verifica se o erro é devido a cliente não encontrado
      if (error.response && error.response.data && typeof error.response.data === 'string' && error.response.data.toLowerCase().includes('cliente não encontrado')) {
        console.log('Cliente não encontrado via erro');
        setProximoAgendamento(null);
        setAgendamentos([]);
        setMostrarAgendamentos(true);
        toast.info('Cliente não encontrado. Você pode agendar um novo horário.');
      } else {
        toast.error('Erro ao buscar histórico de agendamentos. Tente novamente.');
      }
    }
  };

  const handleCreateAppointment = async (appointmentData) => {
    try {
      const { cliente, data, idFuncionario, idServico } = appointmentData;

      if (!cliente.nome || !cliente.telefone || !data || !idFuncionario || !idServico) {
        toast.warn('Preencha todos os campos corretamente antes de agendar.');
        return;
      }

      await api.post(`/cliente/agendamento`, {
        cliente,
        data,
        idFuncionario,
        idServico,
        empresaId,
      });

      toast.success('Agendamento criado com sucesso!');
      setIsModalOpen(false);

      // Atualizar o histórico de agendamentos após criar um novo
      handleCheckAppointments();
    } catch (error) {
      console.error('Erro ao criar agendamento:', error);
      toast.error('Erro ao criar agendamento. Tente novamente.');
    }
  };

  return (
    <div className={styles.container}>
      <img src={logoImg} alt='logo' />

      {/* Se não houver empresaId, mostrar o select de empresas */}
      {!empresaId && (
        <div className={styles.selectEmpresaContainer}>
          <h2>Selecione a Empresa para Agendamento</h2>
          <select className={styles.selectEmpresa} onChange={handleSelectEmpresa} defaultValue=''>
            <option value='' disabled>
              -- Escolha uma Empresa --
            </option>
            {empresas.map((empresa) => (
              <option key={empresa.id} value={empresa.id}>
                {empresa.nome} - {empresa.tipoServico}
              </option>
            ))}
          </select>
        </div>
      )}

      {/* Se empresaId estiver definido, mostrar o formulário de agendamento */}
      {empresaId && (
        <div className={styles.containerInfos}>
          <h1 className={styles.title}>
            Digite seu número de celular <br />
            Cheque e agende seus horários
          </h1>
          <InputMask className={styles.containerInput} mask='(99) 9 9999-9999' value={telefone} onChange={(e) => setTelefone(e.target.value)} placeholder='Digite seu número' />
          <button className={styles.button} onClick={handleCheckAppointments}>
            Agendar
          </button>
        </div>
      )}

      {/* Mostrar agendamentos se mostrarAgendamentos for true */}
      {mostrarAgendamentos && (
        <div className={styles.appointmentsContainer}>
          <h2>Meus Agendamentos</h2>
          {proximoAgendamento ? (
            <div className={styles.nextAppointment}>
              <h3>Próximo Agendamento</h3>
              <p>
                <FiClock /> {new Date(proximoAgendamento.data).toLocaleString()}
              </p>
              <p>Serviço: {proximoAgendamento.servico.tipo}</p>
              <p>Barbeiro: {proximoAgendamento.funcionario?.nome || '---'}</p>
            </div>
          ) : (
            <p>Você não tem agendamentos futuros.</p>
          )}

          <h3>Agendamentos Anteriores</h3>
          {agendamentos.length > 0 ? (
            <ul className={styles.previousAppointments}>
              {agendamentos.map((agendamento, index) => (
                <li key={index}>
                  <p>
                    <strong>Data:</strong> {new Date(agendamento.data).toLocaleString()}
                  </p>
                  <p>
                    <strong>Serviço:</strong> {agendamento.servico.tipo}
                  </p>
                  <p>
                    <strong>Barbeiro:</strong> {agendamento.funcionario?.nome || '---'}
                  </p>
                </li>
              ))}
            </ul>
          ) : (
            <p>Você ainda não realizou nenhum agendamento.</p>
          )}

          <button className={styles.createButton} onClick={() => setIsModalOpen(true)}>
            Criar Novo Agendamento
          </button>
        </div>
      )}

      {/* Modal para criar agendamento */}
      {empresaId && (
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleCreateAppointment}
          providers={providers}
          services={services}
          selectedProvider={selectedProvider}
          setSelectedProvider={setSelectedProvider}
          selectedService={selectedService}
          setSelectedService={setSelectedService}
        />
      )}
    </div>
  );
};

export default ChecarMarcar;
