// src/routes/PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/auth';

const PrivateRoute = ({ children }) => {
  const { user } = useAuth();

  // Se o usuário estiver autenticado, renderiza o componente filho
  if (user) {
    return children;
  }

  // Caso contrário, redireciona para a página de login
  return <Navigate to="/" replace />;
};

export default PrivateRoute;
