import React, { useState } from 'react';
import api from '../../services/api';
import styles from './styles.module.css';

const RegisterProvider = ({ isOpen, onClose, empresaId }) => {
  const [nome, setNome] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [cargo, setCargo] = useState('Barbeiro'); // Cargo padrão para barbeiro
  const [horarioInicio, setHorarioInicio] = useState('08:00');
  const [horarioFim, setHorarioFim] = useState('18:00');
  const [emailError, setEmailError] = useState('');

  if (!isOpen) return null;

  const checkEmailExists = async (email) => {
    try {
      const response = await api.post('/funcionarios/check-email', { email });
      if (response.data.exists) {
        setEmailError('Este email já está cadastrado.');
      } else {
        setEmailError('');
      }
    } catch (error) {
      console.error('Erro ao verificar email:', error);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    if (emailError) {
      alert('Corrija os erros antes de enviar o formulário.');
      return;
    }
    try {
      await api.post('/funcionarios', {
        nome,
        telefone,
        email,
        senha,
        cargo,
        horarioInicio: `2024-11-01T${horarioInicio}:00`,
        horarioFim: `2024-11-01T${horarioFim}:00`,
        empresaId,
      });
      alert('Barbeiro cadastrado com sucesso!');
      setNome('');
      setTelefone('');
      setEmail('');
      setSenha('');
      setHorarioInicio('08:00');
      setHorarioFim('18:00');
      setEmailError('');
      onClose();
    } catch (error) {
      console.error('Erro ao cadastrar barbeiro:', error);
      alert('Erro ao cadastrar barbeiro, tente novamente.');
    }
  };

  return (
    <div className={styles['modal-overlay']}>
      <div className={styles['modal-content']}>
        <h2>Cadastrar Barbeiro</h2>
        <form onSubmit={handleRegister}>
          <label>Nome</label>
          <input
            type="text"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
            required
          />

          <label>Telefone</label>
          <input
            type="text"
            value={telefone}
            onChange={(e) => setTelefone(e.target.value)}
            required
          />

          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              checkEmailExists(e.target.value);
            }}
            required
          />
          {emailError && <span className={styles['error-text']}>{emailError}</span>}

          <label>Senha</label>
          <input
            type="password"
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
            required
          />

          <label>Cargo</label>
          <input
            type="text"
            value={cargo}
            readOnly
          />

          <label>Hora de Início</label>
          <input
            type="time"
            value={horarioInicio}
            onChange={(e) => setHorarioInicio(e.target.value)}
            required
          />

          <label>Hora de Término</label>
          <input
            type="time"
            value={horarioFim}
            onChange={(e) => setHorarioFim(e.target.value)}
            required
          />

          <div className={styles.containerButton}>
            <button className={styles.buttonCancelar} type="button" onClick={onClose}>
              Cancelar
            </button>
            <button className={styles.buttonCadastrar} type="submit">
              Cadastrar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegisterProvider;
