// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Dashboard from './pages/Dashboard';
import { AuthProvider } from './hooks/auth';
import ServicesPage from './pages/ServicesPage';
import ChecarMarcar from './pages/ChecarMarcar';
import ForgotPassword from './pages/ForgotPassword';
import PerfilEmpresa from './pages/PerfilEmpresa';
import BarbeirosPage from './pages/BarbeirosPage';
import PrivateRoute from './routes/PrivateRoute';
import PublicRoute from './routes/PublicRoute'; // Importe o PublicRoute

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          {/* Rotas Públicas */}
          <Route
            path="/login"
            element={
              <PublicRoute>
                <SignIn />
              </PublicRoute>
            }
          />
          <Route
            path="/"
            element={
              <PublicRoute>
                <ChecarMarcar />
              </PublicRoute>
            }
          />
          <Route
            path="/cadastrar"
            element={
              <PublicRoute>
                <SignUp />
              </PublicRoute>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <PublicRoute>
                <ForgotPassword />
              </PublicRoute>
            }
          />
          
          {/* Rotas Privadas */}
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/servicos"
            element={
              <PrivateRoute>
                <ServicesPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/barbeiros"
            element={
              <PrivateRoute>
                <BarbeirosPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/perfil"
            element={
              <PrivateRoute>
                <PerfilEmpresa />
              </PrivateRoute>
            }
          />
          
          
          {/* Opcional: Rota 404 */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
