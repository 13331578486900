// src/pages/BarbeirosPage/index.js
import React, { useState, useEffect } from 'react';
import { FaPlus, FaTrash, FaEdit } from 'react-icons/fa';
import api from '../../services/api';
import styles from './styles.module.css';
import Header from '../../components/Header';
import RegisterBarbeiroModal from '../../components/RegisterBarbeiroModal';
import EditBarbeiroModal from '../../components/EditBarbeiroModal';

const BarbeirosPage = () => {
  const [barbeiros, setBarbeiros] = useState([]);
  const [selectedBarbeiro, setSelectedBarbeiro] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);

  const [newBarbeiro, setNewBarbeiro] = useState({
    nome: '',
    telefone: '',
    email: '',
    senha: '',
    cargo: 'Barbeiro',
    horarioInicio: '08:00',
    horarioFim: '18:00',
  });

  useEffect(() => {
    const fetchBarbeiros = async () => {
      try {
        const response = await api.get('/funcionarios');
        setBarbeiros(response.data);
      } catch (error) {
        console.error('Erro ao buscar barbeiros:', error);
        alert('Erro ao carregar barbeiros. Tente novamente.');
      }
    };

    fetchBarbeiros();
  }, []);

  const handleEditBarbeiro = async () => {
    if (!selectedBarbeiro) return;

    try {
      await api.put(`/funcionarios/${selectedBarbeiro.id}`, {
        nome: selectedBarbeiro.nome,
        telefone: selectedBarbeiro.telefone,
        cargo: selectedBarbeiro.cargo,
      });
      alert('Barbeiro atualizado com sucesso!');
      setIsEditModalOpen(false);
      // Atualizar a lista de barbeiros
      setBarbeiros((prevBarbeiros) =>
        prevBarbeiros.map((barbeiro) =>
          barbeiro.id === selectedBarbeiro.id ? selectedBarbeiro : barbeiro
        )
      );
    } catch (error) {
      console.error('Erro ao atualizar barbeiro:', error);
      alert('Erro ao atualizar barbeiro. Tente novamente.');
    }
  };

  const handleDeleteBarbeiro = async (id) => {
    if (window.confirm('Tem certeza que deseja excluir este barbeiro?')) {
      try {
        await api.delete(`/funcionarios/${id}`);
        setBarbeiros((prevBarbeiros) => prevBarbeiros.filter((barbeiro) => barbeiro.id !== id));
        alert('Barbeiro excluído com sucesso!');
      } catch (error) {
        console.error('Erro ao excluir barbeiro:', error);
        alert('Erro ao excluir barbeiro. Tente novamente.');
      }
    }
  };

  const handleRegisterBarbeiro = async () => {
    if (!newBarbeiro.nome || !newBarbeiro.telefone || !newBarbeiro.email || !newBarbeiro.senha) {
      alert('Preencha todos os campos!');
      return;
    }

    try {
      const response = await api.post('/funcionarios', {
        ...newBarbeiro,
        horarioInicio: `2024-01-01T${newBarbeiro.horarioInicio}:00Z`,
        horarioFim: `2024-01-01T${newBarbeiro.horarioFim}:00Z`,
      });
      setBarbeiros((prevBarbeiros) => [...prevBarbeiros, response.data]);
      alert('Barbeiro cadastrado com sucesso!');
      setNewBarbeiro({
        nome: '',
        telefone: '',
        email: '',
        senha: '',
        cargo: 'Barbeiro',
        horarioInicio: '08:00',
        horarioFim: '18:00',
      });
      setIsRegisterModalOpen(false);
    } catch (error) {
      console.error('Erro ao cadastrar barbeiro:', error);
      alert('Erro ao cadastrar barbeiro. Tente novamente.');
    }
  };

  return (
    <>
      <Header />
      <div className={styles.container}>
        <div className={styles.header}>
          <h1>Barbeiros</h1>
          <button className={styles.createButton} onClick={() => setIsRegisterModalOpen(true)}>
            <FaPlus className={styles.icon} />
            Cadastrar Barbeiro
          </button>
        </div>

        {/* Tabela de Barbeiros */}
        <div className={styles.tableContainer}>
          <table className={styles.barbeirosTable}>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Telefone</th>
                <th>Cargo</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {barbeiros.map((barbeiro) => (
                <tr key={barbeiro.id}>
                  <td>{barbeiro.nome}</td>
                  <td>{barbeiro.telefone}</td>
                  <td>{barbeiro.cargo}</td>
                  <td>
                    <button
                      className={styles.editButton}
                      onClick={() => {
                        setSelectedBarbeiro(barbeiro);
                        setIsEditModalOpen(true);
                      }}
                      title="Editar Barbeiro"
                    >
                      <FaEdit />
                    </button>
                    <button
                      className={styles.deleteButton}
                      onClick={() => handleDeleteBarbeiro(barbeiro.id)}
                      title="Excluir Barbeiro"
                    >
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
              {barbeiros.length === 0 && (
                <tr>
                  <td colSpan="4" className={styles.noData}>
                    Nenhum barbeiro encontrado.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Modal de Cadastro */}
        <RegisterBarbeiroModal
          isOpen={isRegisterModalOpen}
          onClose={() => setIsRegisterModalOpen(false)}
          onRegister={handleRegisterBarbeiro}
          newBarbeiro={newBarbeiro}
          setNewBarbeiro={setNewBarbeiro}
        />

        {/* Modal de Edição */}
        <EditBarbeiroModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          onEdit={handleEditBarbeiro}
          selectedBarbeiro={selectedBarbeiro}
          setSelectedBarbeiro={setSelectedBarbeiro}
        />
      </div>
    </>
  );
};

export default BarbeirosPage;
