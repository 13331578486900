import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/api';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [data, setData] = useState(() => {
    const token = localStorage.getItem('@BarberShop:token');
    const user = localStorage.getItem('@BarberShop:user');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      return { token, user: JSON.parse(user) };
    }

    return {};
  });

  const signIn = useCallback(async ({ telefone, senha }) => {
    try {
      console.log('senha', senha);
      console.log('telefone', telefone);

      const response = await api.post('/login', { telefone, senha });
      console.log('response', response.data);

      const { token, nome, telefone: userTelefone, tipoUsuario } = response.data;

      // Criar objeto de usuário
      const user = {
        id: response.data.id,
        nome,
        telefone: userTelefone,
        tipoUsuario,
      };

      // Armazenar no localStorage
      localStorage.setItem('@BarberShop:token', token);
      localStorage.setItem('@BarberShop:user', JSON.stringify(user));

      // Configurar cabeçalhos para próximas requisições
      api.defaults.headers.authorization = `Bearer ${token}`;

      // Atualizar estado
      setData({ token, user });
    } catch (error) {
      console.error('Erro ao fazer login:', error);
      throw new Error('Erro ao realizar login. Verifique suas credenciais.');
    }
  }, []);

  const signOut = useCallback(() => {
    // Remover dados do localStorage
    localStorage.removeItem('@BarberShop:token');
    localStorage.removeItem('@BarberShop:user');

    // Limpar estado
    setData({});
  }, []);

  return (
    <AuthContext.Provider value={{ user: data.user, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) throw new Error('useAuth must be used within an AuthProvider');
  return context;
}
