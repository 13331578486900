import React, { useState } from 'react';
import styles from './styles.module.css';
import api from '../../services/api';

const CreateServiceModal = ({ isOpen, onClose, onServiceCreated, empresaId }) => {
  const [tipo, setTipo] = useState('');
  const [descricao, setDescricao] = useState('');
  const [valor, setValor] = useState('');
  const [duracao, setDuracao] = useState('');
  const [categoria, setCategoria] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!tipo || !descricao || !valor || !duracao || !empresaId) {
      alert('Preencha todos os campos obrigatórios!');
      return;
    }

    try {
      const response = await api.post('/servicos', {
        tipo,
        descricao,
        valor: parseFloat(valor),
        duracao: parseInt(duracao, 10),
        categoria: categoria || undefined,
        empresaId,
      });

      if (response.data) {
        alert('Serviço criado com sucesso!');
        onServiceCreated(response.data); // Callback para atualizar a lista de serviços no componente pai
        onClose();
        setTipo('');
        setDescricao('');
        setValor('');
        setDuracao('');
        setCategoria('');
      }
    } catch (error) {
      console.error('Erro ao criar serviço:', error);
      alert('Erro ao criar serviço. Tente novamente.');
    }
  };

  if (!isOpen) return null;

  return (
    <div className={styles['modal-overlay']}>
      <div className={styles['modal-content']}>
        <h2>Criar Serviço</h2>
        <form onSubmit={handleSubmit}>
          <label>Tipo de Serviço</label>
          <input
            type="text"
            value={tipo}
            onChange={(e) => setTipo(e.target.value)}
            placeholder="Ex: Corte de cabelo"
            required
          />

          <label>Descrição</label>
          <textarea
            value={descricao}
            onChange={(e) => setDescricao(e.target.value)}
            placeholder="Ex: Corte masculino completo"
            required
          />

          <label>Valor (R$)</label>
          <input
            type="number"
            value={valor}
            onChange={(e) => setValor(e.target.value)}
            placeholder="Ex: 50.00"
            step="0.01"
            required
          />

          <label>Duração (minutos)</label>
          <input
            type="number"
            value={duracao}
            onChange={(e) => setDuracao(e.target.value)}
            placeholder="Ex: 30"
            required
          />

          <label>Categoria (opcional)</label>
          <input
            type="text"
            value={categoria}
            onChange={(e) => setCategoria(e.target.value)}
            placeholder="Ex: Cabelo, barba"
          />

          <div className={styles.containerButton}>
            <button type="submit">Criar Serviço</button>
            <button type="button" onClick={onClose}>
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateServiceModal;
