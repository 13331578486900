// src/components/Header/styles.js
import styled from 'styled-components';

export const Container = styled.header`
  width: 100%;
  background: #2b2b2b;
  padding: 20px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 100;
`;

export const HeaderContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // background: red;
`;
export const Img = styled.img`
  width: 50%;
`;

export const NavLinks = styled.nav`
  display: flex;
  gap: 1rem;

  a {
    text-decoration: none;
  }
`;

export const ButtonService = styled.button`
  display: flex;
  align-items: center;
  gap: 5px;
  background: #e6e6dd;
  color: #2b2b2b;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;

  svg {
    font-size: 1rem;
  }

  &:hover {
    background: #d4d4c4;
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 20px;

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }

  .avatar-placeholder {
    width: 40px;
    height: 40px;
    background: #ff9000;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 1rem;
    text-transform: uppercase;
  }

  div {
    display: flex;
    flex-direction: column;

    span {
      color: #f4ede8;
      font-size: 0.8rem;
    }

    strong {
      color: #ff9000;
      font-size: 0.9rem;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  // margin-left: auto;

  button {
    background: transparent;
    border: none;
    color: #999591;
    cursor: pointer;
    font-size: 1.2rem;
    transition: color 0.3s ease;

    &:hover {
      color: #ff9000;
    }

    &:active {
      transform: scale(0.95);
    }

  /* Estilizando o botão "Gerar Link" diferentemente se necessário */
  button + button {
    margin-left: 10px; /* Espaçamento entre os botões */
  }
  }
`;
