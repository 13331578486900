import React, { useState } from 'react';
import { FiEdit, FiPhone, FiBriefcase, FiX } from 'react-icons/fi';
import logoImg from '../../assets/logo.png';
import styles from './styles.module.css';
import Header from '../../components/Header';
import { useAuth } from '../../hooks/auth';

const PerfilEmpresa = () => {
  const { signOut, user } = useAuth();

  const [empresa, setEmpresa] = useState(user); // Usando os dados simulados
  const [isEditing, setIsEditing] = useState(false);
  const [nome, setNome] = useState(empresa.nome);
  const [telefone, setTelefone] = useState(empresa.telefone);
  const [tipoServico, setTipoServico] = useState(empresa.tipoServico);

  const handleEdit = () => {
    if (!nome || !telefone ) {
      alert('Preencha todos os campos antes de salvar.');
      return;
    }

    // Simula a atualização dos dados
    const dadosAtualizados = { nome, telefone, tipoServico };
    setEmpresa(dadosAtualizados);
    setIsEditing(false);
    alert('Perfil atualizado com sucesso!');
  };

  return (
    <>
      <Header />
      <div className={styles.container}>
        <div className={styles.profileContainer}>
          <h1 className={styles.title}>Perfil da Empresa</h1>
          <div className={styles.infoGroup}>
            <FiBriefcase />
            <p>
              <strong>Nome:</strong> {empresa.nome}
            </p>
          </div>
          <div className={styles.infoGroup}>
            <FiPhone />
            <p>
              <strong>Telefone:</strong> {empresa.telefone}
            </p>
          </div>

          <button className={styles.editButton} onClick={() => setIsEditing(true)}>
            <FiEdit /> Editar Perfil
          </button>
        </div>

        {isEditing && (
          <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
              <button className={styles.closeButton} onClick={() => setIsEditing(false)}>
                <FiX />
              </button>
              <h2>Editar Perfil</h2>
              <div className={styles.inputGroup}>
                <label>Nome da Empresa</label>
                <input type='text' value={nome} onChange={(e) => setNome(e.target.value)} />
              </div>
              <div className={styles.inputGroup}>
                <label>Telefone</label>
                <input type='text' value={telefone} onChange={(e) => setTelefone(e.target.value)} />
              </div>

              <div className={styles.modalButtons}>
                <button className={styles.saveButton} onClick={handleEdit}>
                  Salvar Alterações
                </button>
                <button className={styles.cancelButton} onClick={() => setIsEditing(false)}>
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PerfilEmpresa;
