// src/pages/ServicesPage/index.js
import React, { useState, useEffect } from 'react';
import { FaPlus, FaTrash } from 'react-icons/fa'; // Importando ícones
import CreateServiceModal from '../../components/CreateServiceModal';
import api from '../../services/api';
import styles from './styles.module.css';
import Header from '../../components/Header';
import { useAuth } from '../../hooks/auth';

const ServicesPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [services, setServices] = useState([]);
  const { user } = useAuth();
  console.log('user', user);

  useEffect(() => {
    // Buscar todos os serviços da API
    const fetchServices = async () => {
      try {
        const response = await api.get('/servicos');
        if (response.error) return true;
        console.log('response', response.data);
        setServices(response.data);
      } catch (error) {
        console.error('Erro ao buscar serviços:', error);
        alert('Nenhum serviço cadastrado, cadastre seu primeiro serviço!');
      }
    };

    fetchServices();
  }, []);

  const handleServiceCreated = (newService) => {
    setServices((prevServices) => [...prevServices, newService]);
  };

  const handleDeleteService = async (id) => {
    if (window.confirm('Tem certeza que deseja excluir este serviço?')) {
      try {
        await api.delete(`/servicos/${id}`);
        setServices((prevServices) => prevServices.filter((service) => service.id !== id));
        alert('Serviço excluído com sucesso!');
      } catch (error) {
        console.error('Erro ao excluir serviço:', error);
        alert('Erro ao excluir serviço. Tente novamente.');
      }
    }
  };

  return (
    <>
      <Header />
      <div className={styles.container}>
        <div className={styles.header}>
          <h1>Serviços</h1>
          <button className={styles.createButton} onClick={() => setIsModalOpen(true)}>
            <FaPlus className={styles.icon} />
            Criar Novo Serviço
          </button>
        </div>

        <CreateServiceModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onServiceCreated={handleServiceCreated} empresaId={user.id} />

        <div className={styles.tableContainer}>
          <table className={styles.servicesTable}>
            <thead>
              <tr>
                <th>Tipo</th>
                <th>Descrição</th>
                <th>Valor (R$)</th>
                <th>Duração (min)</th>
                <th>Categoria</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {services.map((service) => (
                <tr key={service.id}>
                  <td>{service.tipo}</td>
                  <td>{service.descricao}</td>
                  <td>R$ {service.valor.toFixed(2)}</td>
                  <td>{service.duracao} min</td>
                  <td>{service.categoria || 'N/A'}</td>
                  <td>
                    <button className={styles.deleteButton} onClick={() => handleDeleteService(service.id)} title='Excluir Serviço'>
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
              {services.length === 0 && (
                <tr>
                  <td colSpan='6' className={styles.noData}>
                    Nenhum serviço encontrado.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ServicesPage;
