// src/components/Header/index.js
import React, { useState } from 'react';
import { FiPower, FiClock, FiLink } from 'react-icons/fi'; // Importar FiLink
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { Container, HeaderContent, Profile, NavLinks, ActionButtons, ButtonService, Img } from './styles.module';
import logoImg from '../../assets/logo.png';
import { toast } from 'react-toastify'; // Importar a função toast

const Header = () => {
  const { signOut, user } = useAuth();
  const navigate = useNavigate();
  const [linkCopied, setLinkCopied] = useState(false); // Estado para feedback

  const handleSignOut = () => {
    signOut();
    navigate('/login');
  };

  const handleGenerateLink = async () => {
    try {
      const baseUrl = window.location.origin;
      const userId = user?.id;
      const generatedLink = `${baseUrl}?idEmpresa=${userId}`; // Ajuste o caminho conforme necessário

      await navigator.clipboard.writeText(generatedLink);
      toast.success('Link copiado para a área de transferência!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLinkCopied(true);
      setTimeout(() => setLinkCopied(false), 3000); // Reseta após 3 segundos
    } catch (error) {
      console.error('Erro ao gerar link:', error);
      alert('Erro ao gerar link. Tente novamente.');
    }
  };

  return (
    <Container>
      <HeaderContent>
        {/* Logo */}
        <Link to='/'>
          <Img src={logoImg} alt='GoBarber' />
        </Link>

        {/* Navegação */}
        <NavLinks>
          <Link to='/servicos'>
            <ButtonService>
              <FiClock />
              Serviços
            </ButtonService>
          </Link>
          <Link to='/barbeiros'>
            <ButtonService>
              <FiClock />
              Barbeiros
            </ButtonService>
          </Link>
          <Link to='/dashboard'>
            <ButtonService>
              <FiClock />
              Dashboard
            </ButtonService>
          </Link>
          <Link to='/perfil'>
            <ButtonService>
              <FiClock />
              Perfil
            </ButtonService>
          </Link>
        </NavLinks>

        {/* Perfil e Ações */}
        <Profile>
          {/* Avatar do Usuário */}
          {user?.avatar ? (
            <img src={user.avatar} alt={user.nome} />
          ) : (
            <div className='avatar-placeholder'>{user?.nome.charAt(0).toUpperCase()}</div>
          )}
          <div>
            <span>Bem-vindo,</span>
            <strong>{user?.nome}</strong>
          </div>
        </Profile>

        {/* Botões de Ação */}
        <ActionButtons>
          {/* Novo Botão: Gerar Link */}
          <button type='button' onClick={handleGenerateLink} title='Gerar Link'>
            <FiLink />
          </button>
          {/* Botão de Logout */}
          <button type='button' onClick={handleSignOut} title='Sair'>
            <FiPower />
          </button>
        </ActionButtons>
      </HeaderContent>

     
    </Container>
  );
};

export default Header;
