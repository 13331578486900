// src/routes/PublicRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/auth';

const PublicRoute = ({ children }) => {
  const { user } = useAuth();

  // Se o usuário já estiver autenticado, redireciona para o dashboard
  if (user) {
    return <Navigate to="/dashboard" replace />;
  }

  // Caso contrário, renderiza o componente filho
  return children;
};

export default PublicRoute;
