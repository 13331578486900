import React, { useState, useEffect } from 'react';
import styles from './styles.module.css';
import api from '../../services/api';

const Modal = ({
  isOpen,
  onClose,
  onSubmit,
  providers,
  services,
  selectedProvider,
  setSelectedProvider,
  selectedService,
  setSelectedService,
}) => {
  const [clientName, setClientName] = useState('');
  const [phone, setPhone] = useState('');
  const [date, setDate] = useState('');
  const [availableTimes, setAvailableTimes] = useState([]); // Horários disponíveis para o dia selecionado
  const [hour, setHour] = useState(''); // Hora selecionada

  // Busca horários disponíveis sempre que 'selectedProvider' ou 'date' mudar
  useEffect(() => {
    if (selectedProvider && date) {
      const fetchAvailableTimes = async () => {
        try {
          const formattedDate = new Date(date).toISOString().split('T')[0]; // Formata a data para 'YYYY-MM-DD'
          const response = await api.get(`/cliente/agendamento/${selectedProvider}`, {
            params: {
              startDate: formattedDate,
            },
          });
          // Filtra apenas horários com status "disponível"
          const horariosDisponiveis = response.data.agendamentos.filter(
            (time) => time.status === 'disponível'
          );
          setAvailableTimes(horariosDisponiveis); // Define os horários disponíveis
        } catch (error) {
          console.error('Erro ao carregar os horários disponíveis', error);
        }
      };

      fetchAvailableTimes();
    }
  }, [selectedProvider, date]);

  if (!isOpen) return null;

  return (
    <div className={styles['modal-overlay']}>
      <div className={styles['modal-content']}>
        <h2>Criar Agendamento</h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();

            // Validações antes de criar o agendamento
            if (!date) {
              alert('Por favor, selecione uma data.');
              return;
            }
            if (!hour) {
              alert('Por favor, selecione um horário.');
              return;
            }

            const appointmentDate = new Date(`${date}T${hour}`);
            if (isNaN(appointmentDate.getTime())) {
              alert('Data ou hora inválida. Verifique e tente novamente.');
              return;
            }

            onSubmit({
              cliente: {
                nome: clientName,
                telefone: phone,
              },
              data: appointmentDate.toISOString(),
              idFuncionario: selectedProvider,
              idServico: selectedService,
            });
          }}
        >
          <label>Barbeiro</label>
          <select
            value={selectedProvider}
            onChange={(e) => setSelectedProvider(e.target.value)}
            required
          >
            <option value="">Selecione um barbeiro</option>
            {providers?.map((provider) => (
              <option key={provider.id} value={provider.id}>
                {provider?.nome}
              </option>
            ))}
          </select>

          <label>Nome do Cliente</label>
          <input
            type="text"
            value={clientName}
            onChange={(e) => setClientName(e.target.value)}
            required
          />

          <label>Telefone do Cliente</label>
          <input
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />

          <label>Data</label>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
          />

          <label>Hora</label>
          <select value={hour} onChange={(e) => setHour(e.target.value)} required>
            <option value="" disabled>
              Selecione uma hora
            </option>
            {availableTimes.map((time, index) => {
              const formattedTime = new Date(time.start).toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
              });
              return (
                <option key={index} value={formattedTime}>
                  {formattedTime}
                </option>
              );
            })}
          </select>

          <label>Serviço</label>
          <select
            value={selectedService}
            onChange={(e) => setSelectedService(e.target.value)}
            required
          >
            <option value="">Selecione um serviço</option>
            {services?.map((service) => {
              console.log('service', service)
              
              return(
              <option key={service.id} value={service.id}>
                {service.tipo} - {service.duracao} min - R$ {service.valor}
              </option>
            )})}
          </select>

          <button type="submit">Agendar</button>
          <button type="button" onClick={onClose}>
            Cancelar
          </button>
        </form>
      </div>
    </div>
  );
};

export default Modal;
