import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import InputMask from 'react-input-mask';
import logoImg from '../../assets/logo.png';
import styles from './styles.module.css';
import api from '../../services/api';

const ForgotPassword = () => {
  const location = useLocation(); // Captura a localização atual
  const query = new URLSearchParams(location.search); // Extrai os query parameters
  const id = query.get('id'); // Obtém o valor do parâmetro 'id'

  const [telefone, setTelefone] = useState('');
  const [novaSenha, setNovaSenha] = useState('');
  const [confirmarSenha, setConfirmarSenha] = useState('');

  const handleEnviarTelefone = async () => {
    if (telefone.length !== 15) {
      alert('Por favor, insira um número válido.');
      return;
    }

    try {
      await api.post('/recuperar-senha', { telefone });
      alert('Um código foi enviado para o número informado.');
    } catch (error) {
      console.error('Erro ao enviar telefone:', error);
      alert('Erro ao processar solicitação. Tente novamente.');
    }
  };

  const handleResetSenha = async () => {
    if (novaSenha !== confirmarSenha) {
      alert('As senhas não coincidem.');
      return;
    }

    try {
      await api.post(`/resetar-senha/${id}`, { senha: novaSenha });
      alert('Senha redefinida com sucesso!');
    } catch (error) {
      console.error('Erro ao redefinir senha:', error);
      alert('Erro ao redefinir senha. Tente novamente.');
    }
  };

  return (
    <div className={styles.container}>
      <a href='/login'>
        <img src={logoImg} alt='logo' />
      </a>
      <div className={styles.containerInfos}>
        {id ? (
          <>
            <h1 className={styles.title}>Redefinir Senha</h1>
            <input type='password' className={styles.containerInput} placeholder='Nova Senha' value={novaSenha} onChange={(e) => setNovaSenha(e.target.value)} />
            <input type='password' className={styles.containerInput} placeholder='Confirmar Nova Senha' value={confirmarSenha} onChange={(e) => setConfirmarSenha(e.target.value)} />
            <button className={styles.button} onClick={handleResetSenha}>
              Redefinir Senha
            </button>
          </>
        ) : (
          <>
            <h1 className={styles.title}>
              Digite seu número de celular <br />
              para recuperar sua senha
            </h1>
            <InputMask className={styles.containerInput} mask='(99) 9 9999-9999' value={telefone} onChange={(e) => setTelefone(e.target.value)} placeholder='Digite seu número' />
            <button className={styles.button} onClick={handleEnviarTelefone}>
              Enviar
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
