// src/pages/Dashboard/styles.module.js
import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  background-color: #e6e6dd;
  min-height: 100vh;
`;

export const Content = styled.main`
  max-width: 1120px;
  margin: 64px auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Sidebar = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  margin-right: 40px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  button {
    background: #ff9000;
    height: 40px;
    border-radius: 10px;
    border: 0;
    padding: 0 16px;
    color: #312e38;
    width: 100%;
    font-weight: 500;
    margin-bottom: 8px;
    transition: background-color 0.2s;

    &:hover {
      background: #e67e00;
    }
  }
`;

export const Schedule = styled.div`
  flex: 1;

  h1 {
    font-size: 24px;
    margin-bottom: 24px;
  }
`;

export const Appointment = styled.div`
  display: flex;
  align-items: center;

  & + div {
    margin-top: 16px;
  }

  span {
    margin-left: auto;
    display: flex;
    align-items: center;
    color: #999591;
    width: 70px;

    svg {
      color: #ff9000;
      margin-right: 8px;
    }
  }

  div {
    flex: 1;
    background: #3e3b47;
    display: flex;
    align-items: center;
    padding: 16px 24px;
    border-radius: 10px;
    margin-left: 24px;

    img {
      width: 56px;
      height: 56px;
      border-radius: 50%;
    }

    strong {
      margin-left: 24px;
      color: #fff;
      font-size: 20px;
    }
  }
`;

export const Calendar = styled.aside`
  width: 380px;
  background-color: #2b2b2b;
  padding: 1rem;
  border-radius: 1rem;
  color: white !important;

  svg {
    color: white !important;
    fill: white !important;
  }

  .DayPicker {
    background: #28262e;
    border-radius: 10px;
    color: white;
  }

  .DayPicker-wrapper {
    padding-bottom: 0;
  }

  .DayPicker,
  .DayPicker-Month {
    width: 100%;
  }

  .DayPicker-Month {
    border-collapse: separate;
    border-spacing: 8px;
    margin: 16px;
  }

  .DayPicker-Day {
    width: 40px;
    height: 40px;
  }

  .DayPicker-Day--available:not(.DayPicker-Day--outside) {
    background: #3e3b47;
    border-radius: 10px;
    color: #fff;
  }

  .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background: ${shade(0.2, '#3e3b47')};
  }

  .DayPicker-Day--today {
    font-weight: normal;
  }

  .DayPicker-Day--disabled {
    color: #666360 !important;
    background: transparent !important;
  }

  .DayPicker-Day--selected {
    background: #ff9000 !important;
    border-radius: 10px;
    color: #232129 !important;
  }
`;
