// src/components/EditBarbeiroModal.js
import React from 'react';
import { FaTimes } from 'react-icons/fa';
import styles from './styles.module.css';

const EditBarbeiroModal = ({ isOpen, onClose, onEdit, selectedBarbeiro, setSelectedBarbeiro }) => {
  if (!isOpen || !selectedBarbeiro) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <h2>Editar Barbeiro</h2>
          <button className={styles.closeButton} onClick={onClose}>
            <FaTimes />
          </button>
        </div>
        <form className={styles.modalForm}>
          <label>Nome</label>
          <input
            type="text"
            value={selectedBarbeiro.nome}
            onChange={(e) =>
              setSelectedBarbeiro((prev) => ({ ...prev, nome: e.target.value }))
            }
            required
          />
          <label>Telefone</label>
          <input
            type="text"
            value={selectedBarbeiro.telefone}
            onChange={(e) =>
              setSelectedBarbeiro((prev) => ({ ...prev, telefone: e.target.value }))
            }
            required
          />
          <label>Cargo</label>
          <input
            type="text"
            value={selectedBarbeiro.cargo}
            onChange={(e) =>
              setSelectedBarbeiro((prev) => ({ ...prev, cargo: e.target.value }))
            }
            required
          />
          <div className={styles.modalButtons}>
            <button type="button" className={styles.saveButton} onClick={onEdit}>
              Salvar
            </button>
            <button type="button" className={styles.cancelButton} onClick={onClose}>
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditBarbeiroModal;
