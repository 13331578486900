import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { isToday, format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import ptBR from 'date-fns/locale/pt-BR';

import 'react-day-picker/style.css';
import { FiClock } from 'react-icons/fi';
import { useAuth } from '../../hooks/auth';
import Modal from '../../components/Modal';
import RegisterProvider from '../../components/RegisterProvider';
import {
  Container,
  Content,
  Schedule,
  Calendar,
  Appointment,
  Sidebar,
  ButtonsContainer,
} from './styles.module';
import Header from '../../components/Header';

import api from '../../services/api';

const Dashboard = () => {
  const { signOut, user } = useAuth();

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [monthAvailability, setMonthAvailability] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRegisterProviderOpen, setIsRegisterProviderOpen] = useState(false);

  // Estados para gerenciar serviços e barbeiros
  const [services, setServices] = useState([]);
  const [providers, setProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState('');
  const [selectedService, setSelectedService] = useState('');

  // Carregar serviços disponíveis
  useEffect(() => {
    api
      .get('/servicos')
      .then((response) => setServices(response.data))
      .catch((error) => console.error('Erro ao buscar serviços:', error));
  }, []);

  // Carregar barbeiros disponíveis
  useEffect(() => {
    api
      .get('/funcionarios', {
        params: { empresaId: user?.empresaId },
      })
      .then((response) => setProviders(response.data))
      .catch((error) => console.error('Erro ao buscar barbeiros:', error));
  }, [user?.empresaId]);

  // Disponibilidade mensal
  useEffect(() => {
    api
      .get('/agendamentos/disponibilidade', {
        params: {
          empresaId: user?.empresaId,
          startDate: `${currentMonth.getFullYear()}-${String(
            currentMonth.getMonth() + 1
          ).padStart(2, '0')}-01`,
          endDate: `${currentMonth.getFullYear()}-${String(
            currentMonth.getMonth() + 1
          ).padStart(2, '0')}-${new Date(
            currentMonth.getFullYear(),
            currentMonth.getMonth() + 1,
            0
          ).getDate()}`,
        },
      })
      .then((response) => {
        const availability = response.data.map((day) => ({
          date: new Date(day.date),
          available: day.status === 'disponível',
        }));
        setMonthAvailability(availability);
      })
      .catch((error) => console.error('Erro ao buscar disponibilidade mensal:', error));
  }, [currentMonth, user?.empresaId]);

  // Agendamentos diários
  useEffect(() => {
    api
      .get('/agendamentos', {
        params: {
          empresaId: user?.empresaId,
          startDate: format(selectedDate, 'yyyy-MM-dd'),
          endDate: format(selectedDate, 'yyyy-MM-dd'),
        },
      })
      .then((response) => {
        const appointmentsFormatted = response.data
          .flatMap((funcionario) =>
            funcionario.agendamentos.filter((appt) => appt.status === 'ocupado')
          )
          .map((appointment) => ({
            ...appointment,
            hourFormatted: format(new Date(appointment.start), 'HH:mm'),
          }));
        setAppointments(appointmentsFormatted);
      })
      .catch((error) => console.error('Erro ao buscar agendamentos diários:', error));
  }, [selectedDate, user?.empresaId]);

  const handleDateChange = useCallback((day) => {
    if (day) {
      setSelectedDate(day);
    }
  }, []);

  const handleMonthChange = useCallback((month) => {
    setCurrentMonth(month);
  }, []);

  const disabledDays = useMemo(() => {
    return monthAvailability
      .filter((day) => !day.available)
      .map((day) => new Date(day.date));
  }, [monthAvailability]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const openRegisterProvider = () => setIsRegisterProviderOpen(true);
  const closeRegisterProvider = () => setIsRegisterProviderOpen(false);

  const handleCreateAppointment = async (appointmentData) => {
    try {
      const response = await api.post('/agendamentos', {
        ...appointmentData,
        empresaId: user?.empresaId,
      });
      alert('Agendamento criado com sucesso!');
      setAppointments((prevAppointments) => [...prevAppointments, response.data]);
      closeModal();
    } catch (error) {
      console.error('Erro ao criar agendamento:', error);
      alert('Erro ao criar agendamento. Tente novamente.');
    }
  };

  return (
    <Container>
      <Header />
      <Content>
        <Sidebar>
          <ButtonsContainer>
            <button onClick={openModal}>Criar Agendamento</button>
          </ButtonsContainer>
          <Schedule>
            <h1>Horários agendados</h1>
            {isToday(selectedDate) && <span>Hoje</span>}
            {appointments.map((appointment) => (
              <Appointment key={appointment.id}>
                <span>
                  <FiClock />
                  {appointment.hourFormatted}
                </span>
                <div>
                  <strong>{appointment.cliente.nome}</strong>
                </div>
              </Appointment>
            ))}
          </Schedule>
        </Sidebar>
        <Calendar>
          <DayPicker
            mode="single"
            selected={selectedDate}
            onSelect={handleDateChange}
            onMonthChange={handleMonthChange}
            disabled={disabledDays}
            locale={ptBR}
            fromMonth={new Date()}
          />
        </Calendar>
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          onSubmit={handleCreateAppointment}
          services={services}
          providers={providers}
          selectedProvider={selectedProvider}
          setSelectedProvider={setSelectedProvider}
          selectedService={selectedService}
          setSelectedService={setSelectedService}
        />
        <RegisterProvider
          isOpen={isRegisterProviderOpen}
          onClose={closeRegisterProvider}
          empresaId={user?.empresaId}
        />
      </Content>
    </Container>
  );
};

export default Dashboard;
