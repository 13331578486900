import React, { useState } from 'react';
import { FiLogIn, FiMail, FiLock } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import logoImg from '../../assets/logo.png'; // Substitua pela imagem do seu logo
import styles from './styles.module.css';
import { useAuth } from '../../hooks/auth';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { signIn } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await signIn({ telefone: email, senha: password });
      navigate('/dashboard');
    } catch (err) {
      alert('Erro ao fazer login. Verifique suas credenciais.');
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles['animation-container']}>
          <img src={logoImg} alt="Barber Shop" />

          <form onSubmit={handleSubmit}>
            <h1>Faça seu login</h1>

            <div>
              <FiMail />
              <input
                type="phone"
                placeholder="telefone"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div>
              <FiLock />
              <input
                type="password"
                placeholder="Senha"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>

            <button type="submit">Entrar</button>

            <a href="/forgot-password">Esqueci minha senha</a>
          </form>

          <a href="/cadastrar">
            <FiLogIn />
            Criar conta
          </a>
        </div>
      </div>
      <div className={styles.background}></div>
    </div>
  );
};

export default SignIn;
