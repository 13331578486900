import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <ToastContainer 
        position="top-right" // Posição da notificação
        autoClose={5000} // Tempo para fechar automaticamente (em ms)
        hideProgressBar={false} // Mostrar barra de progresso
        newestOnTop={false} // Notificações mais recentes em cima
        closeOnClick // Fechar ao clicar
        rtl={false} // Suporte a idiomas RTL
        pauseOnFocusLoss // Pausar ao perder foco
        draggable // Permitir arrastar
        pauseOnHover // Pausar ao passar o mouse
      />
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
