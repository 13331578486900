import React, { useState } from 'react';
import { FiArrowLeft, FiUser, FiPhone, FiLock, FiBriefcase } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import api from '../../services/api';
import logoImg from '../../assets/logo.png';
import styles from './styles.module.css';

const SignUp = () => {
  const [nome, setNome] = useState('');
  const [tipoServico, setTipoServico] = useState('');
  const [telefone, setTelefone] = useState('');
  const [senha, setSenha] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const schema = Yup.object().shape({
        nome: Yup.string().required('Nome obrigatório'),
        tipoServico: Yup.string().required('Tipo de serviço obrigatório'),
        telefone: Yup.string().required('Telefone obrigatório'),
        senha: Yup.string().min(6, 'No mínimo 6 dígitos').required('Senha obrigatória'),
      });

      await schema.validate({ nome, tipoServico, telefone, senha }, { abortEarly: false });

      // Enviando os dados para a API
      await api.post('/empresa', {
        nome,
        tipoServico,
        telefone,
        senha,
      });

      alert('Empresa cadastrada com sucesso!');
      navigate('/'); // Redireciona para a página de login
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        err.errors.forEach((error) => alert(error));
      } else {
        alert('Erro ao fazer o cadastro, tente novamente.');
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.background}></div>
      <div className={styles.content}>
        <div className={styles['animation-container']}>
          <img src={logoImg} alt="Cadastro de Empresa" />

          <form onSubmit={handleSubmit}>
            <h1>Cadastro de Empresa</h1>

            <div>
              <FiUser />
              <input
                name="nome"
                placeholder="Nome da Empresa"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                required
              />
            </div>

            <div>
              <FiBriefcase />
              <input
                name="tipoServico"
                placeholder="Tipo de Serviço"
                value={tipoServico}
                onChange={(e) => setTipoServico(e.target.value)}
                required
              />
            </div>

            <div>
              <FiPhone />
              <input
                name="telefone"
                placeholder="Telefone"
                value={telefone}
                onChange={(e) => setTelefone(e.target.value)}
                required
              />
            </div>

            <div>
              <FiLock />
              <input
                name="senha"
                type="password"
                placeholder="Senha"
                value={senha}
                onChange={(e) => setSenha(e.target.value)}
                required
              />
            </div>

            <button type="submit">Cadastrar Empresa</button>
          </form>

          <Link to="/login">
            <FiArrowLeft />
            Voltar para login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
