// src/components/RegisterBarbeiroModal.js
import React from 'react';
import { FaTimes } from 'react-icons/fa';
import styles from './styles.module.css';

const RegisterBarbeiroModal = ({ isOpen, onClose, onRegister, newBarbeiro, setNewBarbeiro }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <h2>Cadastrar Barbeiro</h2>
          <button className={styles.closeButton} onClick={onClose}>
            <FaTimes />
          </button>
        </div>
        <form className={styles.modalForm}>
          <label>Nome</label>
          <input
            type="text"
            value={newBarbeiro.nome}
            onChange={(e) => setNewBarbeiro((prev) => ({ ...prev, nome: e.target.value }))}
            required
          />
          <label>Telefone</label>
          <input
            type="text"
            value={newBarbeiro.telefone}
            onChange={(e) => setNewBarbeiro((prev) => ({ ...prev, telefone: e.target.value }))}
            required
          />
          <label>Email</label>
          <input
            type="email"
            value={newBarbeiro.email}
            onChange={(e) => setNewBarbeiro((prev) => ({ ...prev, email: e.target.value }))}
            required
          />
          <label>Senha</label>
          <input
            type="password"
            value={newBarbeiro.senha}
            onChange={(e) => setNewBarbeiro((prev) => ({ ...prev, senha: e.target.value }))}
            required
          />
          <label>Cargo</label>
          <input
            type="text"
            value={newBarbeiro.cargo}
            onChange={(e) => setNewBarbeiro((prev) => ({ ...prev, cargo: e.target.value }))}
            required
          />
          <label>Hora de Início</label>
          <input
            type="time"
            value={newBarbeiro.horarioInicio}
            onChange={(e) => setNewBarbeiro((prev) => ({ ...prev, horarioInicio: e.target.value }))}
            required
          />
          <label>Hora de Término</label>
          <input
            type="time"
            value={newBarbeiro.horarioFim}
            onChange={(e) => setNewBarbeiro((prev) => ({ ...prev, horarioFim: e.target.value }))}
            required
          />
          <div className={styles.modalButtons}>
            <button type="button" className={styles.saveButton} onClick={onRegister}>
              Cadastrar
            </button>
            <button type="button" className={styles.cancelButton} onClick={onClose}>
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegisterBarbeiroModal;
